import { default as _91_46_46_46slug_937iyxyngTuBMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/[...slug].vue?macro=true";
import { default as _404tqvf9WyoucMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/404.vue?macro=true";
import { default as indexwFVmoJ4O28Meta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/arbochecklist/index.vue?macro=true";
import { default as bedanktKOFTCUs2y9Meta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/bedankt.vue?macro=true";
import { default as _91slug_93SPnpz7CdCWMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/contact/[slug].vue?macro=true";
import { default as indexj6d0c31iJWMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/contact/index.vue?macro=true";
import { default as indexsmfU3iEJuZMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/index.vue?macro=true";
import { default as _91slug_93lCTaOeSWkyMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/nieuws/[slug].vue?macro=true";
import { default as indexvvushP2VsYMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/nieuws/index.vue?macro=true";
import { default as open_45sollicitatie9hiqxt1wdyMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/open-sollicitatie.vue?macro=true";
import { default as _91_46_46_46slug_93Ao5NzGmlIrMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/over-westerduin/[...slug].vue?macro=true";
import { default as _91slug_93wsAsz0B2DGMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/referenties/[slug].vue?macro=true";
import { default as indexUlqzff1PUMMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/referenties/index.vue?macro=true";
import { default as vacatures_45als_45_91slug_932rael30QVdMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/vacatures-als-[slug].vue?macro=true";
import { default as vacatures_45bij_45_91slug_93QM2YH7KFcWMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/vacatures-bij-[slug].vue?macro=true";
import { default as vacatures_45in_45_91slug_930hWC6kRVsfMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/vacatures-in-[slug].vue?macro=true";
import { default as _91slug_936udLfkjXRNMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/vacatures/[slug].vue?macro=true";
import { default as indexAXMeyI1sh9Meta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/vacatures/index.vue?macro=true";
import { default as vragenbPWOtZzm92Meta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/vragen.vue?macro=true";
import { default as _91_46_46_46slug_935ijVqmIlpvMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/werkgevers/[...slug].vue?macro=true";
import { default as bedanktwl68z4uzKqMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/werkgevers/bedankt.vue?macro=true";
import { default as faqVG8QKv7ibBMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/werkgevers/faq.vue?macro=true";
import { default as registratie_45inlenersbeloning4XEBNVRALNMeta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/werkgevers/registratie-inlenersbeloning.vue?macro=true";
import { default as werkwijzeXScn8ruml0Meta } from "/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/werkwijze.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_937iyxyngTuBMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "arbochecklist",
    path: "/arbochecklist",
    meta: indexwFVmoJ4O28Meta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/arbochecklist/index.vue").then(m => m.default || m)
  },
  {
    name: "bedankt",
    path: "/bedankt",
    meta: bedanktKOFTCUs2y9Meta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/bedankt.vue").then(m => m.default || m)
  },
  {
    name: "contact-slug",
    path: "/contact/:slug()",
    meta: _91slug_93SPnpz7CdCWMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/contact/[slug].vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    meta: indexj6d0c31iJWMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexsmfU3iEJuZMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "nieuws-slug",
    path: "/nieuws/:slug()",
    meta: _91slug_93lCTaOeSWkyMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/nieuws/[slug].vue").then(m => m.default || m)
  },
  {
    name: "nieuws",
    path: "/nieuws",
    meta: indexvvushP2VsYMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/nieuws/index.vue").then(m => m.default || m)
  },
  {
    name: "open-sollicitatie",
    path: "/open-sollicitatie",
    meta: open_45sollicitatie9hiqxt1wdyMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/open-sollicitatie.vue").then(m => m.default || m)
  },
  {
    name: "over-westerduin-slug",
    path: "/over-westerduin/:slug(.*)*",
    meta: _91_46_46_46slug_93Ao5NzGmlIrMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/over-westerduin/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "referenties-slug",
    path: "/referenties/:slug()",
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/referenties/[slug].vue").then(m => m.default || m)
  },
  {
    name: "referenties",
    path: "/referenties",
    meta: indexUlqzff1PUMMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/referenties/index.vue").then(m => m.default || m)
  },
  {
    name: "vacatures-als-slug",
    path: "/vacatures-als-:slug()",
    meta: vacatures_45als_45_91slug_932rael30QVdMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/vacatures-als-[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacatures-bij-slug",
    path: "/vacatures-bij-:slug()",
    meta: vacatures_45bij_45_91slug_93QM2YH7KFcWMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/vacatures-bij-[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacatures-in-slug",
    path: "/vacatures-in-:slug()",
    meta: vacatures_45in_45_91slug_930hWC6kRVsfMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/vacatures-in-[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacatures-slug",
    path: "/vacatures/:slug()",
    meta: _91slug_936udLfkjXRNMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/vacatures/[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacatures",
    path: "/vacatures",
    meta: indexAXMeyI1sh9Meta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/vacatures/index.vue").then(m => m.default || m)
  },
  {
    name: "vragen",
    path: "/vragen",
    meta: vragenbPWOtZzm92Meta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/vragen.vue").then(m => m.default || m)
  },
  {
    name: "werkgevers-slug",
    path: "/werkgevers/:slug(.*)*",
    meta: _91_46_46_46slug_935ijVqmIlpvMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/werkgevers/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "werkgevers-bedankt",
    path: "/werkgevers/bedankt",
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/werkgevers/bedankt.vue").then(m => m.default || m)
  },
  {
    name: "werkgevers-faq",
    path: "/werkgevers/faq",
    meta: faqVG8QKv7ibBMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/werkgevers/faq.vue").then(m => m.default || m)
  },
  {
    name: "werkgevers-registratie-inlenersbeloning",
    path: "/werkgevers/registratie-inlenersbeloning",
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/werkgevers/registratie-inlenersbeloning.vue").then(m => m.default || m)
  },
  {
    name: "werkwijze",
    path: "/werkwijze",
    component: () => import("/data/www/westerduin.nl/westerduin/releases/8/nuxt/pages/werkwijze.vue").then(m => m.default || m)
  }
]